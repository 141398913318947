import { FC } from "react";
import { Row, ListGroup, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import 'animate.css';

interface Item {
    id_enlace: number;
    titulo: string;
    tipo: string;
    id_estatus: number;
    url: string;
}

interface ItemProps {
    items: Item[];
    titulo: string;
    tipo?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const downloadFile = async (id_enlace: number, filename: string) => {
    try {
        const response = await fetch(`${API_URL}/files/${id_enlace}/${filename}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
    }
};

const LeftSideBarWrapper: FC<ItemProps> = ({ items, titulo }) => {
    const { url } = useParams();

    return (
        <div className="leftSideBar">
            <Row>
                <Col>
                    <h1>{titulo}</h1>
                </Col>
            </Row>

            <ListGroup>
                {items.map((item, index) => {
                    const delay = `${index * 0.1}s`;
                    const commonProps = {
                        style: { animationDelay: delay },
                        className: "pt-3 pb-3 animate__animated animate__bounceInLeft",
                        key: index,
                    };

                    switch (item.tipo) {
                        case "info":
                        case "titulo":
                        case "titulo_perfil":
                        case "acord":
                        case "video":
                        case "libro":
                            return (
                                <ListGroup.Item {...commonProps} href={`#${item.url}`} as="a">
                                    {item.titulo}
                                </ListGroup.Item>
                            );
                        case "file":
                            return (
                                <ListGroup.Item
                                    {...commonProps}
                                    href={`#${item.url}`}
                                    as="a"
                                    onClick={() => downloadFile(item.id_enlace, item.url)}
                                >
                                    {item.titulo}
                                </ListGroup.Item>
                            );
                        case "url":
                            return (
                                <ListGroup.Item {...commonProps} href={item.url} as="a">
                                    {item.titulo}
                                </ListGroup.Item>
                            );
                        case "carrera":
                            return (
                                <ListGroup.Item
                                    {...commonProps}
                                    active={url === item.url}
                                    to={`/Oferta_Educativa/${item.url}`}
                                    as={Link}
                                >
                                    {item.titulo}
                                </ListGroup.Item>
                            );
                        default:
                            return null;
                    }
                })}
            </ListGroup>
        </div>
    );
};

export default LeftSideBarWrapper;
