import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import LeftSideBarWrapper from './LeftSideBarWrapper';
import Footer from './Footer';
import HeaderWrapper from './HeaderWrapper';
import GoUpButton from './GoUpButton';
import ImageGallery from './Gallery/ImageGallery';
import AccordContent from './Content/acord';
import EmblaCarousel from './EmblaCarousel/Embla';
import { EmblaOptionsType } from 'embla-carousel';
import './EmblaCarousel/base.css';
import './EmblaCarousel/embla.css';
import InfoComponent from './Content/info';
import VideoLoader from './Multimedia/VideoLoader';
import ReactPlayer from 'react-player';

const API_URL = process.env.REACT_APP_API_URL;
const OPTIONS: EmblaOptionsType = { containScroll: false }

function ContentPage() {
    const { url } = useParams();
    const [sidebarMenuItems, setSidebarMenuItems] = useState<any[]>([]);
    const [info, setInfo] = useState<any>(null);
    const navigate = useNavigate();

    const getChilds = async (enlaceId: any) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/getChilds`,
                { id: enlaceId }
            )
            const items = response.data
            setSidebarMenuItems(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    const getInfo = async () => {
        try {
            const response = await axios.get(`${API_URL}/enlaces/url/${url}`)
            const items = response.data;
            setInfo(items);
            if (items.tipo === "titulo" || items.tipo === "acord") {
                await getChilds(items.id_enlace);
            }
        } catch (error) {
            //console.log('Error: ', error)
            navigate('/404');
        }
    }

    useEffect(() => {
        getInfo();
    }, [url])

    return (
        <>
            <HeaderWrapper />
            <Container fluid>
                <Row>
                    {info ?
                        (info.tipo === "titulo" || info.tipo === "acord") ?
                            <>
                                <Col className='menuBox' md={3} >
                                    <LeftSideBarWrapper items={sidebarMenuItems} titulo={info.titulo} />
                                </Col>
                                <Col className="content-wrapper ps-5" md={6} tabIndex={0}>
                                    {sidebarMenuItems.map((ite: any, index: number) => {
                                        switch (ite.tipo) {
                                            case "info": return <div key={index} id={ite.url}>
                                                <InfoComponent url={ite.url} titulo={ite.titulo} cuerpo={ite.cuerpo} />
                                                <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /> </div>
                                            </div>
                                            case "libro":
                                                return <div id={ite.url}><div className="theme-light">
                                                    <h2>{ite.titulo}</h2>
                                                    <EmblaCarousel images={ite.imagenes} options={OPTIONS} />
                                                </div>
                                                    <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /> </div></div>
                                            case "video":
                                                return <div id={ite.url}>
                                                    <div className="theme-light">
                                                        <h4>{ite.titulo}</h4>
                                                        <ReactPlayer
                                                            width="100%"
                                                            url={`https://api.upchiapas.edu.mx/video/${ite.id_enlace}/${ite.url}`}
                                                            controls={true} />
                                                    </div>
                                                    <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /> </div>
                                                </div>
                                            case "acord":
                                                return <div key={index} id={ite.url}>
                                                    <AccordContent url={ite.url} id_enlace={ite.id_enlace} titulo={ite.titulo} />
                                                    <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /></div>
                                                </div>
                                            case "titulo":
                                                return (info.tipo === "acord") ?
                                                    <div key={index} id={ite.url}>
                                                        <AccordContent url={ite.url} id_enlace={ite.id_enlace} titulo={ite.titulo} />
                                                        <div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /></div>
                                                    </div> : <div key={index} id={ite.url}><VideoLoader filename={ite.titulo} id_enlace={ite.id_enlace} /><div className='mb-5 pb-5 mt-5 pt-5'><hr style={{ width: '60%' }} /></div></div>
                                            default: return <></>
                                        }
                                    }

                                    )}
                                </Col>
                                <Col md={3} className='rightSideBar'></Col>
                                <GoUpButton />
                            </>
                            :
                            <>
                                <Col className='menuBox text-break' md={3} >
                                    <h1>{info.titulo}</h1>
                                </Col>
                                <Col className='content-wrapper animate__animated animate__fadeIn' md={6}>
                                    <div style={{ textAlign: 'justify' }} className='content-wrapper'
                                        dangerouslySetInnerHTML={{ __html: info.cuerpo }}
                                    />
                                    {(info.parent_id === 80) ? <ImageGallery id_enlace={info.id_enlace} /> : <></>}
                                </Col>
                                <Col md={3} className='rightSideBar'></Col>
                            </>
                        :
                        <Row className="justify-content-center m-2">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Row>
                    }
                </Row >
            </Container>
            <Footer />
        </>
    )
}

export default ContentPage;