import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { SliderItem } from '../../Interfaces/Interfaces';
import ImageLoader from '../Multimedia/ImageLoader';

interface SliderProps {
    items: SliderItem[];  // Cambia aquí el nombre de la interfaz a SliderProps
}

const Slider: React.FC<SliderProps> = ({ items }) => {
    return (
        <Carousel>
            {
                items.map((item: SliderItem, index: number) => (
                    (item.url !== "") ?
                        <Carousel.Item
                            key={index}
                            as="a"
                            href={item.url}>
                                <ImageLoader src={`https://api.upchiapas.edu.mx/slider/${item.imagen}`} alt={`Slider ${index}`}/>
                        </Carousel.Item>
                        :
                        <Carousel.Item key={index}>
                            <ImageLoader src={`https://api.upchiapas.edu.mx/slider/${item.imagen}`} alt={`Slider ${index}`}/>
                        </Carousel.Item>

                )
                )
            }
        </Carousel>

    );
}

export default Slider;