import React from 'react'
import ReactPlayer from 'react-player'
import axios from 'axios';

interface VideoLoaderProps {
    filename: string;
    id_enlace: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const VideoLoader: React.FC<VideoLoaderProps> = ({filename, id_enlace }) => {
    const [videos, setVideos] = React.useState<any[]>([])

    const getChilds = async (enlaceId: any) => {
        try {
            const response = await axios.post(`${API_URL}/enlaces/getChilds`,
                { id: enlaceId }
            )
            const items = response.data
            console.log(items)
            setVideos(items)
        } catch (error) {
            console.log('Error: ', error)
        }
    }

    React.useEffect(() => {
        getChilds(id_enlace);
    }, [id_enlace])

    return (
        <>
        <h2>{filename}</h2>
            {videos.map((video, index) =>
                <div key={`vid_${index}`} className='pb-5 mb-2'>
                    <h4>{video.titulo}</h4>
                    <ReactPlayer 
                        width="100%"
                        url={`https://api.upchiapas.edu.mx/video/${video.id_enlace}/${video.url}`}
                        controls={true} />
                </div>
            )}
        </>
    )
}

export default VideoLoader;